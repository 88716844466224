<template>
  <div v-loading="loading" element-loading-text="正在上传..." class="container">
    <div class="top">
      <!-- <img src="../../assets/left.icon.png" alt="" srcset="" /> -->
      <div>上传到我的相册</div>
    </div>
    <div class="title">
      <span>已上传</span>
      <div>
        (<span>{{ length + currentCount }} / <span></span>{{ maxCount }}</span>)
      </div>
    </div>
    <div class="content">
      <div class="transverse">
        <div>
          <span class="text-title">横向图片</span>
          <!-- <span class="text-details">(横向图片说明)</span> -->
        </div>
        <div style="margin-top: 0.2rem; display: flex; flex-flow: wrap">
          <div 
            v-for="(item, index) in fileListtransverse"
            :key="index"
            class="imgbox"
          >
            <img
              class="upimg"
              :src="item.url"
              alt=""
              srcset=""
            />
            <img class="del" src="./../../assets/del.png" alt="" @click="deletePic(1,index)">
            <!-- <img v-if="!item.delFlag" class="remove" src="./../../assets/remove.png" alt="" @click="removePic(1,index)"> -->
          </div>
           
          <el-upload
            class="upload-demo"
            action="#"
            :disabled="disabled"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-change="handleSuccess"
            :http-request="uploadFile"
            :before-remove="beforeRemove"
            :limit="maxCount-currentCount-length"
            :on-exceed="handleExceed"
            accept="image/jpeg,image/jpg,image/png"
            :file-list="fileList"
            multiple
          >
            <div type="primary" class="border" @click="addImg">
              <img src="../../assets/add.png" alt="" srcset="" />
            </div>
          </el-upload>
        </div>
      </div>
    </div>
    <div class="button-content">
      <div class="button" @click="savePic">上传</div>
    </div>
  </div>
</template>

<script>
import { getId, exchangeUploadToken, uploadUrl, savePic, deletePic } from "../../api/api.js";
import * as qiniu from "qiniu-js";
import { getUrlParams } from "../../../utils/qin-ui";
import md5 from 'js-md5';
export default {
  name: "indexInfo",
  data() {
    return {
      name: "aaa",
      fileList: [],
      fileList1: [],
      tempToken: "",
      fileUrl: "",
      loading:false,
      percent: 0,
      percentv:0,
      ok: 0,
      oks: 0,
      qiniuInfo: {},
      fileListVertical: [],
      fileListtransverse:[],
      uploadImg:[],
      status: true,
      accountId: 16516,
      successList: [],
      length: 0,
      userInfo: {
        accountId: this.accountId
      },
      img: {
        imgWidth: "",
        imgHeight: "",
      },
      currentCount: 0,
      maxCount: 0,
      disabled: false
    };
  },
  methods: {
    getqinui() {
      exchangeUploadToken({ tempToken: this.tempToken, accountId: 561 }).then(
        (res) => { 
          console.log(res);
        }
      );
    },
    getList() {
      this.fileListVertical = []
      this.fileListtransverse = []
      getId(this.accountId).then((res) => {
        const list = res.data.data.groupData
        let list2 = []
        list.map(item => {
          list2 = [...list2, ...item.picUploadList]
        })
        list2.map(item => {
          if (item.orientation === '1') {
            this.fileListtransverse.push(item)
          } else {
            this.fileListVertical.push(item)
          }
        })
      });
    },
    beforeUpload(file) {
      const isSize = new Promise(function (resolve, reject) {
        let _URL = window.URL || window.webkitURL;
        let image = new Image();
        image.src = _URL.createObjectURL(file);
        image.onload = function () {
          let valid = image.width < image.height;
          console.log(valid)
          valid ? resolve() : reject();
        };
        // console.log(645646,image.src)
        // this.uploadImg=[image.src]
      }).then(
        () => {
          return file;
        },
        () => {
          this.status=false
          this.$message.error("图片应为竖向摆放");
          return Promise.reject();
          
        }
      );
    },
    beforeUploadV(file) {
      const isSize = new Promise(function (resolve, reject) {
        let _URL = window.URL || window.webkitURL;
        let image = new Image();
        image.onload = function () {
          let valid = image.width > image.height;
          
          valid ? resolve() : reject();
        };
        image.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.status=false
          this.$message.error("图片应为横向摆放");
          return Promise.reject();
          
        }
      );
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleRemoveVertical(file, fileList1) {
      console.log(file, fileList1);
      this.percent = 0;
    },
    handlePreview(file) {
      console.log(11);
      console.log(file);
      console.log(this.tempToken);
    },
    getRandom() {
      var num = [];
      for(var i = 0; i < 5; i++){
          num[i] = Math.floor(Math.random()*10) + 1;  //范围是[1~10]
      }
      const num2 = num.join('');
      return num2
    },
    getSize(file) {
      return new Promise((resolve, reject) => {
        let _URL = window.URL || window.webkitURL;
        let image = new Image();
        image.src = _URL.createObjectURL(file);
        image.onload = function () {
          let valid = image.width < image.height;
          let fourKit = image.height >= 2160 && image.width>=3840
          resolve ({ valid: valid, fourKit: fourKit});
        }
      });
    },
    addImg() {
      if (this.disabled) {
         this.$message.error("图片上传数量达到上限");
      }
    },
    uploadFile(file) {

      const _this = this;
      const name = file.file.name.split(/[\t\r\f\n\s]*/g).join('');
      const temp = new File([file.file], name);
      let _URL = window.URL || window.webkitURL;
        let image = new Image();
        image.src = _URL.createObjectURL(file.file);
        image.onload = function () {
          let fourKit = image.height >= 2160 && image.width>=3840
          let valid = image.width > image.height;
          if (!valid) {
            _this.$message.error("图片应为横向摆放");
            return;
          }
          let md55;
          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(file.file);
          fileReader.onload = (ev) => {
            // 获取文件的arrayBuffer 数据
            const arrayBuffer = ev.target.result;
            // 利用md5-js插件获取MD5值
            md55 = md5(arrayBuffer);
            console.log(md55);
            var fileName = file.file.name;
            const time = Date.now();
            
          _this.fileListtransverse.push({
            url: image.src,
            fileName:time + "_" + _this.getRandom() + "_" + file.file.name.split(/[\t\r\f\n\s]*/g).join(''),
            file: temp,
            fourKit:fourKit ? "1" : "0",
            orientation: "2",
            imagemd5: md55
          });
          console.log(_this.fileListtransverse);
            // const time = Date.now();
            // var fileName=file.file.name
            // const observable = qiniu.upload(
            //   file.file,
            //   time+'_'+_this.getRandom()+'_'+ fileName,
            //   _this.qiniuInfo.qnToken
            // );
            // observable.subscribe({
            //   next: (result) => {
            //     _this.oks = 1;
            //     console.log("next====");
            //     console.log(result);
            //     _this.percentv = result.total.percent;
            //   },
            //   error: (res) => {
            //     console.log("error===");
            //     console.log(res);
            //     _this.$message.error("上传失败");
            //   },
            //   complete: (res) => {
            //     console.log("成功", res);
            //     console.log("complete====1111");
            //     _this.ok = 0;
            //     // console.log(this.qiniuInfo.host);
            //     _this.fileUrl = _this.qiniuInfo.imgUrl + res.key;
            //     const obj = {
            //         url: _this.fileUrl,
            //         accountId: _this.accountId,
            //         orientation: '1',
            //         fourKit: fourKit?'1':'0',
            //         imagemd5: md55
            //       }
            //       _this.fileListtransverse.push(obj);
            //     _this.status=true
            //   },
            // });
          };
        }
      // const time = Date.now();
      // var fileName=file.file.name
      // const observable = qiniu.upload(
      //   file.file,
      //   time+'_'+this.getRandom()+'_'+ fileName,
      //   this.qiniuInfo.qnToken
      // );
      // observable.subscribe({
      //   next: (result) => {
      //     this.oks = 1;
      //     console.log("next====");
      //     console.log(result);
      //     this.percentv = result.total.percent;
      //   },
      //   error: (res) => {
      //     console.log("error===");
      //     console.log(res);
      //     this.$message.error("上传失败");
      //   },
      //   complete: (res) => {
      //     console.log("成功", res);
      //     console.log("complete====1111");
      //     this.ok = 0;
      //     // console.log(this.qiniuInfo.host);
      //     this.fileUrl = this.qiniuInfo.imgUrl + res.key;
      //     if(this.status===true){
      //       const obj = {
      //         url: this.fileUrl,
      //         accountId: this.accountId,
      //         orientation: '1',
      //         fourKit: x.fourKit
      //       }
      //       this.fileListtransverse.push(obj);
      //     }
      //     this.status=true
      //   },
      // });
    },
    uploadFileVertical(file) {
      var fileName=file.file.name
      const time = Date.now();
      const observable = qiniu.upload(
        file.file,
        time+'_'+this.getRandom()+'_'+ fileName,
        this.qiniuInfo.qnToken
      );
      console.log(333, observable);
      observable.subscribe({
        next: (result) => {
          this.ok = 1;
          console.log("next====");
          console.log(result);
          this.percent = result.total.percent;
        },
        error: (res) => {
          console.log("error===");
          console.log(res);
          this.$message.error("上传失败");
        },
        complete: (res) => {
          console.log("成功", res);
          console.log("complete====1111");
          this.ok = 0;
          // console.log(this.qiniuInfo.host);
          this.fileUrl = this.qiniuInfo.imgUrl + res.key;
          // if(this.status===true){this.fileListVertical.push(this.fileUrl);}
          if(this.status===true){
            const obj = {
              url: this.fileUrl,
              accountId: this.accountId,
              orientation: '2'
            }
            this.fileListVertical.push(obj);
          }
          this.status=true
          console.log(1111, this.fileUrl);
          console.log(this.fileListVertical);
        },
      });
    },
    handleSuccess(file, fileList) {
      this.fileList = fileList;
      
      // let formData = new FormData();
      // formData.append('file', file.raw);
      // console.log(formData);
    },
    handleSuccessVertical(file, fileList) {
      console.log("shu");
      console.log(this.fileList1, file, fileList);
      this.fileList1 = fileList;
    },
    handleExceed(files, fileList) {
      console.log(fileList, files);
      const num = this.maxCount - this.currentCount - this.length;
      this.$message.warning(`只能上传${num}张图片`);
    },
    handleExceedVertical(files, fileList1) {
      console.log(fileList1, files);
      this.$message.warning("只能选择一个文件，若要更换文件，请删除原来的文件");
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeRemoveVertical(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // savePic2(){
    //   const arr = [...this.fileListtransverse, ...this.fileListVertical];
    //   console.log(this.fileListVertical)
    //   this.loading = true
    //   savePic({ picUploadFormList: arr}).then(res => {
    //     if (res.data.errcode === 0){
    //       this.length += this.fileListtransverse.length;
    //       this.$message.success("画册上传成功");
    //       this.fileListVertical = []
    //       this.fileListtransverse = []
    //     } else {
    //       this.$message.error(res.data.errmsg)
    //     }
    //   })
    // },
    savePic() {
      if (this.fileListtransverse.length === 0) {
        this.$message.error("请上传照片");
        return;
      }
      if (!this.qiniuInfo) {
        this.$message.error("token过期, 请重新扫码");
        return;
      }
      this.loading = true;
      this.fileListtransverse.map(item => {
         let md55;
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(item.file);
      fileReader.onload = (ev) => {
        // 获取文件的arrayBuffer 数据
        const arrayBuffer = ev.target.result;
        // 利用md5-js插件获取MD5值
        md55 = md5(arrayBuffer);
        console.log(md55);
        var fileName = item.fileName;
        const time = Date.now();
        const observable =  qiniu.upload(
          item.file,
          time + "_" + this.getRandom() + "_" + fileName,
          this.qiniuInfo.qnToken
        );
        observable.subscribe({
          next: (result) => {
            this.ok = 1;
            console.log("next====");
            console.log(result);
            this.percent = result.total.percent;
          },
          error: (res) => {
            console.log("error===");
            console.log(res);
            this.loading = false
            this.$message.error("上传失败");
          },
          complete: (res) => {
            console.log("成功", res);
            console.log("complete====1111");
            this.ok = 0;
            // console.log(this.qiniuInfo.host);
            this.fileUrl = this.qiniuInfo.imgUrl + res.key;
            console.log(this.fileUrl, this.status);
            // if(this.status===true){this.fileListVertical.push(this.fileUrl);}
            if (this.status === true) {
              console.log('0000');
              const obj = {
                url: this.fileUrl,
                accountId: this.accountId,
                orientation: "1",
                fourKit: item.fourKit,
                imagemd5: item.imagemd5,
              };
              this.successList.push(obj);
              console.log('22222');
              console.log(this.successList, this.fileListtransverse);
              if (this.successList.length === this.fileListtransverse.length) {
                const arr = [ ...this.successList];
                savePic({ picUploadFormList: arr }).then((res) => {
                  this.loading = false;
                  this.length += this.fileListtransverse.length;
                  if (res.data.errcode === 0) {
                    this.$message.success("画册上传成功");
                    this.fileListtransverse = []
                    this.successList = []
                    this.fileList = []
                    this.disabled = this.maxCount - this.currentCount - this.length === 0 ? true: false
                  } else {
                    this.$message.error(res.data.errmsg)
                  }
                });
              }
            }
            this.status = true;
          },
        });
      };
      });
      
    },
    deletePic(type, index) {
      // deletePic(id).then(res => {
      //   this.getList()
      // })
      if (type === 1) {
        this.fileListtransverse.splice(index, 1)
        this.fileList.splice(index, 1)
      } else {
        this.fileListVertical.splice(index, 1)
      }
    },
    removePic(type, index) {
      if (type) {
        this.fileListtransverse.splice(index, 1)
      }
    },
     // 获取七牛上传token
    getQiniuToken() {
      exchangeUploadToken({ tempToken: this.tempToken, accountId: this.accountId }).then(
          (res) => {
            if (res.data.errcode !== 0) {
              this.$message.error(res.data.errmsg);
            }
            console.log(res.data.data);
            this.qiniuInfo = res.data.data;
            this.currentCount = this.qiniuInfo.currentCount;
            this.maxCount = this.qiniuInfo.maxCount;
            this.disabled = this.maxCount - this.currentCount === 0 ? true : false;
            console.log(this.qiniuInfo);
          }
        );
    },
  },
  created() {
    let href = window.location.href;
    href = href.substring(0, href.length);
    const hrefObj = getUrlParams(href);
    console.log(hrefObj);
    this.tempToken = hrefObj.tempToken;
    this.accountId = hrefObj.accountId;
    this.getQiniuToken();

  }
};
</script>

<style>
.el-upload-list__item-name {
  display: none !important;
}
* {
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  height: 100vh;
  background-color: rgba(247, 245, 246);
}
.el-message{
  min-width: 100px !important;
  padding: 7px 10px !important;
  white-space: nowrap;
}
 .el-message__icon {
  font-size: 20px !important;
}
.top {
  padding: 0 0.4rem;
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.92rem;
  background-color: #fff;
  font-size: 0.32rem;
  text-align: center;
  display: flex;
  align-items: center;
  clear: both;
}

.top > div {
  margin: 0 auto;
}

.top > img {
  width: 0.2rem;
  height: 0.32rem;
}

.title {
  width: 100%;
  height: 0.6rem;
  font-size: 0.32rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.24rem;
  box-sizing: border-box;
}

.content {
  width: 100%;
  padding: 0rem 0.24rem;
  box-sizing: border-box;
}
.upimg {
  width: 1.50rem;
  height: 1.50rem;
  margin-right:0.1rem;
  margin-bottom: 0.04rem;
  border-radius: .2rem;

}
.transverse,
.vertical {
  width: 100%;
  min-height: 5rem;
  background-color: #fff;
  border-radius: 0.2rem;
  margin-bottom: 0.3rem;
  padding: .12rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.text-title {
  font-size: 0.32rem;
}

.text-details {
  font-size: 0.28rem;
  margin-left: 0.2rem;
}

.transverse > div:first-child,
.vertical > div:first-child {
  width: 100%;
  height: 0.4rem;
  display: flex;
  align-items: center;
}

.upload-demo {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: flex-start;
}
.border {
  width: 1.50rem;
  height: 1.50rem;
  border: 0.02rem solid #ddd;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgbox {
  position: relative;
}
.del {
  position: absolute;
  right: .16rem;
  top: .06rem;
  z-index: 100;
  width: .25rem;
  height: .25rem;
}
.remove {
  position: absolute;
  right: 0.1rem;
  top: 0rem;
  z-index: 100;
  width: .38rem;
  height: .38rem;
}
.button-content {
  width: 100%;
  padding: 0 0.4rem;
  box-sizing: border-box;
  position: relative;
  bottom: 0.1rem;
  left: 0;
}

.button {
  width: 100%;
  height: 0.92rem;
  color: #fff;
  font-size: 0.36rem;
  text-align: center;
  line-height: 0.92rem;
  border-radius: 0.5rem;
  background: linear-gradient(to right, #f04e88, #f26502);
}

</style>
